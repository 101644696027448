<template>
  <v-app>
    <v-row class="fill-height">
      <v-container>
        <v-card>
          <v-card-text>
            <v-form ref="verification" v-if="username.data">
              <v-autocomplete label="Username" v-model="username.value" :items="username.data" item-text="username" item-value="username"></v-autocomplete>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
    <Footer/>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: {
          data: null,
          value: null,
      },
      form: {
        loading: false,
        data: {
          name: null,
          phone: null,
          address: null,
          province: null,
          district: null,
          subdistrict: null,
          status: null,
          id_card_image: null,
        },
        rules: {
          name: [(v) => !!v || "Nama harus diisi"],
          phone: [(v) => !!v || "Nomor telepon harus diisi"],
          address: [(v) => !!v || "Alamat harus diisi"],
          province: [(v) => !!v || "Provinsi harus diisi"],
          district: [(v) => !!v || "Kabupaten/Kota harus diisi"],
          subdistrict: [(v) => !!v || "Kecamatan harus diisi"],
          status: [(v) => !!v || "Status harus diisi"],
          id_card_image: [(v) => !!v || "Foto KTP harus diisi"],
        },
      },
    };
  },
  methods: {
    async getMemberUsername() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/username`)
        .then((res) => {
          this.username.data = res.data;
          console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
      Footer: () => import("@/components/Footer.vue"),
  },
  mounted(){
      this.getMemberUsername();
  }
};
</script>